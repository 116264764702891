import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import * as style from './index.module.css'

const IndexPage = () => {
  return (
    <Layout pageTitle="Spiked">
      <Helmet>
        <meta name="description" content="Spiked Film starring Aidan Qiunn" />
        <title>Home | Spiked Film</title>
        <body className="bg-img index-bg-img" />
      </Helmet>
      <div className={style.videoContainer}></div>
      <iframe
        className={style.videoIframe}
        src="https://www.youtube.com/embed/xmO40nhvvEw?autoplay=1"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </Layout>
  )
}

export default IndexPage
